const React = require('react');
const { useContext } = require('react');
const { arrayOf, shape, string } = require('prop-types');
const classNames = require('classnames');
const StaticPropsContext = require('../context/static-props');
const RelatedDesktop = require('./related.desktop');
const RelatedMobile = require('./related.mobile');

const RELATED_DEVICE_MOBILE = 'mobile';

const RelatedWrapper = ({ label, items, className, namespace }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const classes = classNames(namespace, className);

  const getRelatedMarkup = () => {
    switch (deviceType) {
      case RELATED_DEVICE_MOBILE:
        return <RelatedMobile label={label} items={items} namespace={namespace} />;
      default:
        return <RelatedDesktop label={label} items={items} namespace={namespace} />;
    }
  };

  const relatedMarkup = getRelatedMarkup();

  return <div className={classes}>{relatedMarkup}</div>;
};

RelatedWrapper.propTypes = {
  namespace: string,
  className: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
};

RelatedWrapper.defaultProps = {
  namespace: null,
  className: null,
};

module.exports = RelatedWrapper;

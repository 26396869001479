const React = require('react');
const { shape, arrayOf, string } = require('prop-types');
const IconFactory = require('../icons/factory-builder')();

const RelatedMobile = ({ label, items, namespace }) => {
  const listItems = items.map(item => (
    <li className={`${namespace}__item`} key={item.id}>
      <a className={`${namespace}__link`} href={item.href}>
        <span className={`${namespace}__link-icon ${namespace}__link-column`}>
          {IconFactory({ id: 'search', color: 'GRAY' })}
        </span>
        <p className={`${namespace}__link-text ${namespace}__link-column`}>{item.text}</p>
      </a>
    </li>
  ));

  return (
    <>
      <h2 className={`${namespace}__title`}>{label}</h2>
      <ul className={`${namespace}__list`}>{listItems}</ul>
    </>
  );
};

RelatedMobile.propTypes = {
  namespace: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
};

RelatedMobile.defaultProps = {
  namespace: null,
  className: null,
};

module.exports = RelatedMobile;
